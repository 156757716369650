<template>
  <div class="mx-3 max-w-screen-md text-sm">
    <label class="later-form-label flex items-center w-32">
      <span v-text="`Attachments (${value.length})`" />
      <div
        v-if="
          this.$store.state.user && this.$store.state.user.plan.name !== 'free'
        "
      >
        <i
          :class="{
            'cursor-pointer hover:text-black hover:bg-gray-light': !disabled,
          }"
          title="Attach files"
          class="material-icons text-lg text-gray-dark rounded m-1 p-1"
        >
          attachment
        </i>
        <input
          v-if="!disabled"
          type="file"
          class="hidden"
          @change="handleChange"
        />
      </div>
      <i
        v-else
        title="Upgrade your account to send attachments"
        class="
          material-icons
          text-lg text-gray-dark
          rounded
          m-1
          p-1
          cursor-pointer
          hover:text-black hover:bg-gray-light
        "
        @click="upgradeDialog('Upgrade account to send attachments')"
        >attachment</i
      >
    </label>
    <div
      v-for="(attachment, index) in value"
      :key="index"
      class="flex items-center bg-gray-lighter rounded my-2 px-2"
    >
      <a
        v-if="attachment.hash"
        :href="`/attachment/${attachment.hash}`"
        class="truncate"
        v-text="attachment.name"
      />
      <span
        v-else
        :class="{ 'text-error': attachment.error }"
        class="flex w-5/6"
      >
        <span class="truncate" v-text="attachment.name" />
        <span
          v-if="attachment.progress"
          class="mx-2 text-secondary"
          v-text="` ${attachment.progress}%`"
        />
      </span>
      <span
        class="text-gray-dark ml-2"
        v-text="`(${humanBytes(attachment.size)})`"
      ></span>
      <i
        title="Remove attachment"
        class="
          material-icons
          cursor-pointer
          rounded
          hover:text-black hover:bg-gray-light
          text-lg text-gray-dark
          ml-auto
        "
        @click="removeAttachment(index)"
        >close</i
      >
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { humanBytes } from '@/utils/string';
import { upgradeDialog } from '@/utils/dialog';

export default {
  props: {
    value: { type: Array, required: true },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    humanBytes,
    upgradeDialog,
    handleChange(event) {
      if (
        !event ||
        !event.target ||
        !event.target.files ||
        event.target.files.length < 1
      )
        throw new Error('No file to upload');
      const attach = {
        name: event.target.files[0].name,
        size: event.target.files[0].size,
        type: event.target.files[0].type,
        progress: 0,
      };
      this.$emit('input', [...this.value, attach]);
      const fd = new FormData();
      fd.append('file', event.target.files[0]);
      this.progress = 0;
      axios
        .post(`${this.$route.path}/attachment`, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (pEvent) => {
            attach.progress = parseInt(
              Math.round((pEvent.loaded * 100) / pEvent.total),
              10
            );
          },
        })
        .then((response) => {
          this.$set(attach, 'hash', response.data.hash);
        })
        .catch(() => {
          this.$set(attach, 'error', true);
          this.$delete(attach, 'progress');
        });
    },
    removeAttachment(index) {
      const attach = this.value[index];
      if (attach.hash) {
        axios
          .delete(`${this.$route.path}/attachment/${attach.hash}`)
          .then(() => {
            this.$delete(this.value, index);
            this.$store.commit(
              'snackbarMessage',
              `Attachment '${attach.name}' removed successfully.`
            );
          });
      } else this.$delete(this.value, index);
    },
  },
};
</script>
