<template>
  <div>
    <div class="later-form-label" v-text="label" />
    <mdc-button
      v-for="option in options"
      :key="option.id"
      :raised="value.includes(option)"
      ripple
      dense
      class="mr-1 mb-1"
      @click.native.prevent="fireEvent(option)"
      v-html="option.label"
    />
  </div>
</template>

<script>
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: {
    MdcButton,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  mounted() {
    const ids = this.value.map((obj) => obj.id);
    this.options.forEach((option) => {
      this.$set(option, 'selected', ids.includes(option.id));
    });
  },
  methods: {
    fireEvent(option) {
      option.selected = !option.selected;
      this.$emit(
        'input',
        this.options.filter((opt) => opt.selected)
      );
    },
  },
};
</script>
