<template>
  <div />
</template>

<script>
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/css/plugins/code_view.min.css';

// import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml';
import FroalaEditor from 'froala-editor';

export default {
  props: {
    value: { type: String, required: true },
    hasFocus: { type: Boolean, default: false },
  },

  data: () => ({
    editor: null,
  }),

  mounted() {
    // eslint-disable-next-line
    this.editor = new FroalaEditor(this.$el, {
      // https://froala.com/wysiwyg-editor/docs/plugins/
      key: process.env.VUE_APP_FROALA_KEY,
      attribution: false,
      charCounterCount: false,
      codeMirror: CodeMirror,
      emoticonsUseImage: false,
      enter: FroalaEditor.ENTER_BR,
      events: {
        initialized: () => {
          this.editor.html.set(this.value);
        },
        contentChanged: () => {
          this.$emit('input', this.editor.html.get());
        },
      },
      heightMax: 600,
      heightMin: 200,
      htmlAllowedEmptyTags: [
        'textarea',
        'a',
        'iframe',
        'object',
        'video',
        'style',
        'script',
        '.fa',
        '.fr-emoticon',
        '.fr-inner',
        'path',
        'line',
        'hr',
        'div',
        'p',
        'br',
      ],
      htmlRemoveTags: ['script'],
      htmlUntouched: true,
      iframe: false,
      imageEditButtons: [
        'imageAlign',
        'imageDisplay',
        'imageAlt',
        'imageSize',
        '-',
        'imageLink',
        'linkRemove',
        'linkEdit',
        'linkOpen',
        '|',
        'imageReplace',
        'imageRemove',
      ],
      imagePaste: false,
      imageUpload: false,
      linkAlwaysBlank: true,
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkList: [
        {
          text: 'Later.io',
          href: 'https://later.io',
          target: '_blank',
          rel: 'nofollow',
        },
      ],
      pasteDeniedTags: [],
      placeholderText: false,
      pluginsEnabled: [
        'align',
        'codeBeautifier',
        'codeView',
        'colors',
        'draggable',
        'emoticons',
        'fontFamily',
        'fontSize',
        'fullscreen',
        'help',
        'image',
        'lineBreaker',
        'link',
        'lists',
        'print',
        'quote',
        'specialCharacters',
        'table',
        'url',
        'video',
        'wordPaste',
      ],
      quickInsertEnabled: false,
      tableInsertHelper: false,
      tableEditButtons: [
        'tableCellBackground',
        'tableCells',
        'tableRows',
        'tableColumns',
        '-',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        'tableHeader',
        '|',
        'tableRemove',
      ],
      toolbarButtons: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'fontFamily',
            'fontSize',
            'strikeThrough',
            'textColor',
            'backgroundColor',
            'clearFormatting',
          ],
          buttonsVisible: 3,
        },
        moreParagraph: {
          buttons: [
            'quote',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'alignJustify',
            'formatOL',
            'formatUL',
            'outdent',
            'indent',
            'insertHR',
          ],
          buttonsVisible: 1,
        },
        moreRich: {
          buttons: [
            'insertImage',
            'emoticons',
            'insertLink',
            'insertVideo',
            'insertTable',
            'specialCharacters',
          ],
          buttonsVisible: 2,
        },
        moreMisc: {
          buttons: ['undo', 'redo', 'fullscreen', 'print', 'html', 'help'],
          align: 'right',
          buttonsVisible: 2,
        },
      },
      toolbarButtonsXS: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'fontFamily',
            'fontSize',
            'strikeThrough',
            'textColor',
            'backgroundColor',
            'clearFormatting',
          ],
          buttonsVisible: 0,
        },
        moreParagraph: {
          buttons: [
            'alignLeft',
            'alignCenter',
            'alignRight',
            'alignJustify',
            'formatOL',
            'formatUL',
            'outdent',
            'indent',
            'quote',
            'insertHR',
          ],
          buttonsVisible: 0,
        },
        moreRich: {
          buttons: [
            'insertLink',
            'insertImage',
            'emoticons',
            'insertVideo',
            'insertTable',
            'specialCharacters',
          ],
          buttonsVisible: 0,
        },
        moreMisc: {
          buttons: ['undo', 'redo', 'fullscreen', 'html'],
          align: 'right',
          buttonsVisible: 2,
        },
      },
      toolbarSticky: false,
      videoUpload: false,
      videoInsertButtons: ['videoByURL'],
    });
  },

  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
div#body {
  @apply py-2 border-0;
  & /deep/ .fr-toolbar {
    @apply rounded;
    & .fr-btn {
      transition: background-color 0.25s;
      & svg path {
        fill: #128cc3;
      }
      &.fr-active {
        background-color: #128cc3;
        & svg path {
          fill: white;
        }
      }
    }
    &.fr-toolbar-open {
      @apply p-0;
    }
    & .fr-more-toolbar,
    & .fr-dropdown-wrapper {
      transition: height 0.1s;
    }
  }
  & /deep/ .fr-view {
    @apply font-email text-lg text-black;
  }
  & /deep/ .fr-second-toolbar {
    @apply rounded;
  }
}
</style>
