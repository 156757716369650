<template>
  <div
    :class="{ 'pointer-events-none': disabled }"
    class="flex flex-1 items-center py-2"
    @click="$refs.input.focus()"
  >
    <label class="later-form-label" style="flex-basis: 30px" v-text="label" />
    <draggable
      :list="contacts"
      :options="{
        draggable: '.chipper',
        handle: '.chipper',
        animation: 150,
        ghostClass: 'opacity-25',
        group: 'contacts',
      }"
      class="flex flex-1 flex-wrap w-full"
      @change="$emit('input', contacts)"
      @end="$refs.input.focus()"
    >
      <button
        v-for="(contact, index) in contacts"
        :key="index"
        :class="{
          'bg-error': contact.invalid,
          'border border-gray-light border-solid': !contact.invalid,
          'chipper cursor-move': !disabled,
          'select-none': disabled,
          'hover:shadow': !disabled && !contact.invalid && !contact.selected,
          'bg-primary-lighter': contact.selected,
        }"
        class="
          relative
          whitespace-no-wrap
          pointer-events-auto
          flex-none
          rounded
          mr-1
          mb-1
          flex
          items-center
        "
        @click="selectionChange($event, contact)"
      >
        <i v-if="!contact.email" class="material-icons text-base pl-1">group</i>
        <span
          :title="contactTitle(contact)"
          :class="{
            'text-gray font-light': disabled,
            'line-through text-gray font-light': contact.deleted_at,
          }"
          class="px-1"
          v-html="contact.name || contact.email"
        />
        <i
          v-if="!disabled"
          class="
            material-icons
            p-1
            text-sm text-gray
            rounded
            cursor-pointer
            hover:text-black hover:font-bold
          "
          @click="removeFromList(index)"
          >close</i
        >
      </button>
      <span v-if="contacts.length === 0" class="w-1 chipper"></span>
      <span
        slot="footer"
        class="relative inline-block flex flex-grow flex-shrink items-baseline"
      >
        <input
          ref="input"
          v-model="textInput"
          :disabled="disabled"
          :style="{ minWidth: '15rem' }"
          class="
            w-full
            px-2
            py-1
            mb-1
            text-base
            rounded
            hover:bg-gray-lighter
            focus:bg-gray-light
          "
          type="text"
          autocomplete="off"
          spellcheck="false"
          @input="typing"
          @paste.prevent="pasted"
          @keydown.down="moveMenuItem(1, $event)"
          @keydown.up="moveMenuItem(-1, $event)"
          @keydown.esc="dropdownItems = []"
          @keydown.8="backspaced"
          @keydown.9.13.32.186.188="processInput($event)"
          @keydown.meta.65="selectAll"
          @keydown.meta.67.88="copySelection"
          @blur="blurred($event)"
        />
        <div
          v-show="dropdownItems.length > 0"
          ref="dropdown"
          class="absolute bg-white shadow-lg z-20 mt-8"
        >
          <div
            v-for="(item, index) in dropdownItems"
            :key="index"
            :class="{ 'bg-gray-light': index == selectedMenuIndex }"
            class="dd-item cursor-pointer p-2 flex items-center"
            tabindex="0"
            @mouseover="selectedMenuIndex = index"
            @click="addToList(item)"
          >
            <i
              :class="item.email ? 'text-gray-dark' : 'text-black'"
              class="
                material-icons
                rounded-full
                shadow
                p-1
                mr-4
                bg-gray-lighter
              "
              v-text="item.email ? 'person' : 'group'"
            />
            <div :class="{ 'line-through': item.deleted_at }" class="flex-1">
              <div v-text="item.name" />
              <div
                :class="{ 'text-xs text-gray-dark': item.name }"
                v-text="
                  item.email || `Group with ${item.contacts_count} contacts.`
                "
              />
            </div>
          </div>
        </div>
      </span>
    </draggable>
  </div>
</template>

<script>
// todo: shorten full list when blurred
import debounce from 'debounce';
import draggable from 'vuedraggable';
import axios from '@/utils/xhr';
import { emailValidate } from '@/utils/email';

export default {
  components: {
    draggable,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    contacts: [],
    textInput: '',
    pasting: false,
    selectedMenuIndex: -1,
    dropdownItems: [],
  }),

  mounted() {
    this.contacts = this.value.slice();
    this.typing = debounce(this.typing, 200);
    if (this.autofocus) this.$refs.input.focus();
  },

  methods: {
    typing() {
      if (this.textInput.trim() === '') {
        this.dropdownItems = [];
        return;
      }
      this.selectAll(false);

      axios
        .post('/email/contacts/search', { query: this.textInput })
        .then((response) => {
          this.dropdownItems = response.data;
          this.selectedMenuIndex = 0;
          this.$nextTick(() => {
            const dropdownRight =
              this.$refs.dropdown.getBoundingClientRect().right;
            const parentElRight =
              this.$refs.dropdown.parentElement.getBoundingClientRect().right;
            if (dropdownRight > parentElRight) {
              this.$refs.dropdown.style.left = 'auto';
              this.$refs.dropdown.style.right = '0px';
            } else {
              this.$refs.dropdown.style.left = '0px';
              this.$refs.dropdown.style.right = 'auto';
            }
          });
        });
    },

    contactTitle(contact) {
      if (!contact.email) return 'Group of contacts';
      if (contact.deleted_at)
        return 'Disabled contact - see contacts page for details';
      return `${contact.name} <${contact.email}>`;
    },

    commitContacts(contacts) {
      if (contacts.length === 0) return;
      axios
        .post('/email/contacts', { contacts })
        .then((response) => {
          this.contacts
            .filter((contact) => !contact.hash)
            .forEach((contact) => {
              const match = response.data.find(
                (c) => c.email.toUpperCase() === contact.email.toUpperCase()
              );
              if (match) {
                contact.name = match.name;
                contact.deleted_at = match.deleted_at;
                this.$set(contact, 'hash', match.hash);
              }
            });
          // response.data.reverse().forEach((resp) => {
          //   for (let i = this.contacts.length - 1; i >= 0; i -= 1) {
          //     if (resp.email.toUpperCase() === this.contacts[i].email.toUpperCase()) {
          //       this.$set(this.contacts[i], 'hash', resp.hash);
          //       this.contacts[i].name = resp.name;
          //       break;
          //     }
          //   }
          // });
          this.$emit('input', this.contacts);
        })
        .catch(() => {
          contacts.forEach((contact) => {
            this.$set(contact, 'invalid', true);
          });
        });
    },

    blurred(event) {
      if (event.sourceCapabilities) {
        if (
          !event.relatedTarget ||
          !event.relatedTarget.className ||
          !event.relatedTarget.className.includes('dd-item')
        )
          if (this.textInput.trim().length > 0)
            this.validateEmail(this.textInput, false);
        if (
          !event.relatedTarget ||
          !event.relatedTarget.className ||
          !event.relatedTarget.className.includes('chipper')
        )
          this.selectAll(false);
      }
    },

    pasted(event) {
      this.pasting = true;
      const input = event.clipboardData.getData('text/plain');
      const cleanup = input.replace(/[\s]+/gim, ' ');
      let sum = '';
      const newContacts = [];
      for (let i = 0; i < cleanup.length; i += 1) {
        const c = cleanup.charAt(i);
        if (c === ' ' || c === ',' || c === ';') {
          const contact = this.validateEmail(sum);
          if (!contact.invalid) {
            newContacts.push(contact);
            sum = '';
          } else if (sum !== '') sum += c;
        } else sum += c;
      }
      if (sum.length > 1) {
        const contact = this.validateEmail(sum, false);
        if (!contact.invalid) newContacts.push(contact);
      }
      this.commitContacts(newContacts);
      this.pasting = false;
    },

    processInput(event) {
      if (event.keyCode === 13) event.preventDefault();
      if (this.textInput.trim().length === 0) return;
      if (this.dropdownItems.length > 0 && event.keyCode !== 32) {
        event.preventDefault();
        this.addToList(this.dropdownItems[this.selectedMenuIndex]);
        return;
      }
      if (event.keyCode === 9) this.validateEmail(this.textInput, false);
      else if (!this.validateEmail(this.textInput).invalid)
        event.preventDefault();
    },

    validateEmail(str, onlyAddValid = true) {
      const obj = emailValidate(str);
      if (!obj.invalid || !onlyAddValid) this.addToList(obj);
      return obj;
    },

    addToList(contact) {
      if (this.textInput.length > 0) this.textInput = '';
      if (this.dropdownItems.length > 0) this.dropdownItems = [];

      // gmail vs inbox ? decided not to remove user-entered dups
      // // eslint-disable-next-line
      // if (contact.valid === false) {} // keep invalids
      // else if (contact.email) {
      //   const strCmp = contact.email.toUpperCase();
      //   if (this.contacts.findIndex(c => c.email && c.email.toUpperCase() === strCmp) >= 0) return;
      // }
      // else if (this.contacts.findIndex(c => !c.email && c.hash === contact.hash) >= 0) return;

      this.contacts.push(contact);
      if (!this.pasting && !contact.hash && !contact.invalid)
        this.commitContacts([contact]);
      else this.$emit('input', this.contacts);
    },

    removeFromList(index) {
      this.contacts.splice(index, 1);
      this.$emit('input', this.contacts);
    },

    backspaced() {
      if (
        this.textInput.length === 0 &&
        this.dropdownItems.length === 0 &&
        this.contacts.length > 0
      ) {
        const nonSelected = this.contacts.filter((c) => !c.selected);
        if (nonSelected.length === this.contacts.length)
          this.removeFromList(this.contacts.length - 1);
        else {
          this.contacts = nonSelected;
          this.$emit('input', this.contacts);
        }
      }
    },

    moveMenuItem(dir, event) {
      if (this.dropdownItems.length === 0) return;
      if (event) event.preventDefault();

      if (this.selectedMenuIndex + dir < 0) this.selectedMenuIndex = 0;
      else if (this.selectedMenuIndex + dir >= this.dropdownItems.length)
        this.selectedMenuIndex = this.dropdownItems.length - 1;
      else this.selectedMenuIndex += dir;
    },

    selectionChange(event, contact) {
      if (!event.metaKey) {
        this.contacts.forEach((c) => {
          if (c.selected && c !== contact) c.selected = false;
        });
      }
      this.$set(contact, 'selected', true);
    },

    selectAll(select = true) {
      this.contacts.forEach((c) => {
        this.$set(c, 'selected', select);
      });
    },

    copySelection(event) {
      navigator.clipboard.writeText(
        this.contacts
          .filter((c) => c.selected)
          .map((c) => `${c.name} <${c.email}>`)
          .join(', ')
      );
      if (event.keyCode === 88) this.backspaced();
    },
  },
};
</script>
