<style lang="scss" scoped>
.stepper {
  &:hover {
    .steppers {
      opacity: 1;
    }
  }
  /deep/ input {
    text-align: center;
  }
  i {
    user-select: none;
  }
}
</style>

<template>
  <div class="stepper">
    <div class="flex items-center">
      <mdc-textfield
        v-model="counter"
        :label="label"
        :min="min"
        :max="max"
        class="w-16"
        type="number"
        @blur="ensureValid"
        @input="$emit('input', counter)"
        @mousewheel.native.prevent="mouser"
      />
      <div class="steppers flex flex-col opacity-25">
        <i
          class="
            material-icons
            text-base text-gray
            hover:text-black hover:bg-gray-light
            rounded
            cursor-pointer
          "
          @mousedown="kick(1)"
          @mouseup="stop"
          @mouseout="stop"
        >
          keyboard_arrow_up
        </i>
        <i
          class="
            material-icons
            text-base text-gray
            hover:text-black hover:bg-gray-light
            rounded
            cursor-pointer
          "
          @mousedown="kick(-1)"
          @mouseup="stop"
          @mouseout="stop"
        >
          keyboard_arrow_down
        </i>
      </div>
      <span class="ml-1">
        <slot />
      </span>
    </div>
  </div>
</template>

<script>
import MdcTextfield from './mdc/textfield.vue';

export default {
  components: {
    MdcTextfield,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 999,
    },
  },
  data: () => ({
    counter: 1,
    numKeys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    delKeys: ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'],
    timer: null,
    wait: 1000,
  }),
  mounted() {
    this.counter = this.value;
  },
  methods: {
    kick(direction) {
      this.step(direction);
      this.timer = setTimeout(() => this.kick(direction), this.wait);
      if (this.wait > 100) this.wait /= 4;
    },
    step(direction) {
      this.ensureValid();
      const newVal = this.counter + direction;
      if (newVal >= this.min && newVal <= this.max) {
        this.counter += direction;
        this.$emit('input', this.counter);
      }
    },
    mouser(event) {
      this.step(event.deltaY > 0 ? 1 : -1);
    },
    stop() {
      clearTimeout(this.timer);
      this.wait = 1000;
    },
    ensureValid() {
      if (!Number.isInteger(this.counter) || this.counter < this.min) {
        this.counter = this.min;
        this.$emit('input', this.counter);
      } else if (this.counter > this.max) {
        this.counter = this.max;
        this.$emit('input', this.counter);
      }
    },
  },
};
</script>
