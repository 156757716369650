<style>
.flatpickr-calendar {
  top: 4rem !important;
  left: 0 !important;
}
</style>

<template>
  <div :title="tooltiptext" class="relative">
    <input
      :id="inputId"
      type="text"
      tabindex="-1"
      class="absolute opacity-0 mt-5 z--10 w-0 h-0"
    />
    <mdc-textfield
      v-model="caltext"
      :disabled="disabled"
      :label="label"
      :readonly="multiple"
      :required="required"
      :invalid="error"
      :style="{ width: '14rem' }"
      tabindex="-1"
      type="search"
      @click="flatpickr.open()"
      @focus="flatpickr.open()"
      @keydown.9="flatpickr.close()"
      @input="userInput"
    />
    <i
      v-if="multiple && flatpickr && flatpickr.isOpen"
      :style="{ right: '1rem', bottom: '1.2rem' }"
      class="
        absolute
        material-icons
        text-sm
        p-1
        text-gray-dark
        hover:bg-gray-light
        rounded-full
        cursor-pointer
      "
      title="Clear all dates"
      @click="flatpickr.clear()"
      >clear</i
    >
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import Flatpickr from 'flatpickr';
import { dateFromServer, dateString } from '@/utils/date';
import axios from '@/utils/xhr';
import MdcTextfield from './mdc/textfield.vue';

export default {
  components: {
    MdcTextfield,
  },

  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    flatpickr: null,
    caltext: '',
    dirty: false,
    error: false,
  }),

  computed: {
    tooltiptext() {
      return dateString(this.value);
    },
    inputId() {
      return `flatcal-${this._uid}`;
    },
  },

  mounted() {
    const firstDate = this.multiple ? this.value[0] : this.value;
    const minDate =
      firstDate < this.$store.state.currentTime
        ? firstDate
        : this.$store.state.currentTime;

    this.flatpickr = new Flatpickr(document.getElementById(this.inputId), {
      allowInput: false,
      altInput: true,
      altFormat: 'Y-m-d h:i K',
      appendTo: this.$el,
      dateFormat: 'Y-m-d H:i:S',
      enableTime: true,
      mode: this.multiple ? 'multiple' : 'single',
      minDate: minDate.toJSDate(),
      minuteIncrement: 1,
      onChange: (dateObj) => {
        if (this.multiple) {
          this.$emit(
            'input',
            dateObj.sort(this.dateCompare).map((dt) => DateTime.fromJSDate(dt))
          );
          this.caltext = `${dateObj.length} dates selected`;
        } else {
          const dateTimeObj = DateTime.fromJSDate(dateObj[0]);
          if (dateTimeObj.ts !== this.value.ts)
            this.$emit('input', dateTimeObj);
          this.caltext = dateTimeObj.toLocaleString(DateTime.DATETIME_SHORT);
        }
      },
      onClose: () => {
        if (!this.multiple) {
          if (
            this.caltext.trim().length === 0 &&
            this.value &&
            this.value.isValid
          ) {
            this.$emit('input', DateTime.invalid('NO_DATE'));
          } else if (this.dirty && this.caltext.trim().length > 0) {
            axios
              .post('/test-date', {
                date: this.caltext,
              })
              .then((response) => {
                this.flatpickr.setDate(
                  dateFromServer(response.data.date).toJSDate(),
                  true
                );
              })
              .catch(() => {
                this.$emit('input', DateTime.invalid('BAD_DATE'));
                this.error = true;
              });
          }
          this.dirty = false;
        }
      },
    });
    if (this.multiple) {
      this.flatpickr.setDate(
        this.value.map((dt) => dt.toJSDate()),
        true
      );
    } else if (this.value.isValid)
      this.flatpickr.setDate(this.value.toJSDate(), true);
  },

  beforeDestroy() {
    this.flatpickr.destroy();
  },

  methods: {
    openCal() {
      if (this.flatpickr) this.flatpickr.open();
    },
    closeCal() {
      if (this.flatpickr) this.flatpickr.close();
    },
    dateCompare(a, b) {
      return a.getTime() - b.getTime();
    },
    userInput() {
      this.dirty = true;
      this.error = false;
      this.$emit(
        'input',
        DateTime.invalid(
          this.caltext.trim().length === 0 ? 'NO_DATE' : 'BAD_DATE'
        )
      );
    },
  },
};
</script>
