export function emailValidate(str) {
  const reEmailOnly =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const reEmailAndName = /^([^<]*)<([^>]+)>$/;
  if (reEmailOnly.test(str)) return { name: '', email: str };
  if (reEmailAndName.test(str)) {
    const matches = str.match(reEmailAndName);
    const name = matches[1].replace(/^[\s'"“]+|[\s'"”]+$/g, '');
    const email = matches[2].trim();
    if (reEmailOnly.test(email)) return { name, email };
    return { invalid: true, name, email };
  }
  return { invalid: true, name: '', email: str };
}

export function emailDisplay(contact) {
  return contact.name.length > 0 ? contact.name : contact.email;
}
