<template>
  <div>
    <mdc-textfield
      v-if="!hasFocus || disabled"
      v-model="recurComputed"
      :disabled="disabled"
      :invalid="value && value.invalid"
      class="w-full"
      label="Send"
      readonly
    />
    <div v-else class="flex flex-wrap">
      <div class="mr-6">
        <div class="flex flex-wrap">
          <mdc-select
            v-model="every"
            :options="everyOptions"
            class="mr-6"
            label="Repeat"
            dense
            @input="changeInterval"
          />
          <later-stepper
            v-if="['day', 'week', 'month', 'year'].includes(every.id)"
            v-model="everyX"
            label="Every"
            @input="doIt"
          >
            <span v-text="plural(every.id, everyX)" />
          </later-stepper>
          <later-calendar
            v-else-if="every.id === 'dates'"
            v-model="dates"
            label="Choose dates"
            multiple
            @input="doIt"
          />
          <div v-else-if="every.id === 'times'">
            <div
              v-for="date in datesTimes"
              :key="date.i"
              class="flex items-center"
            >
              <later-calendar
                v-model="date.d"
                label="Enter Date and Time"
                @input="doIt"
              />
              <mdc-button
                ripple
                dense
                title="Remove this recurrence"
                @click.native.stop="removeDateTime(date.i)"
              >
                <i class="material-icons">close</i>
              </mdc-button>
            </div>
            <div class="flex h-16">
              <mdc-button
                class="mb-4 self-end"
                title="Add another recurrence"
                ripple
                raised
                dense
                @click.native="addDateTime"
              >
                <i class="material-icons">add</i>
              </mdc-button>
            </div>
          </div>
          <div v-else-if="every.id === 'manual'" class="flex">
            <mdc-textarea
              v-model="textareaInput"
              label="Dates..."
              class="w-64 font-mono border"
              @input="inputTextArea"
              @blur.stop="processTextarea"
            />
            <mdc-icon
              title="Validate all the dates"
              @click.native="processTextarea"
            >
              done
            </mdc-icon>
          </div>
        </div>
        <div v-if="every.id === 'week'" class="my-2">
          <later-button-group
            v-model="weekdays"
            :options="weekdaysOptions"
            label="On"
            @input="doIt"
          />
        </div>
        <div v-if="every.id === 'year'" class="my-2">
          <later-button-group
            v-model="months"
            :options="monthsOptions"
            label="In"
            @input="doIt"
          />
        </div>
        <div v-if="['month', 'year'].includes(every.id)" class="flex">
          <mdc-select
            v-model="onthe"
            :options="ontheOptions"
            class="mr-6"
            label="On the"
            dense
            @input="doIt"
          />
          <later-button-group
            v-if="onthe.id === 'days'"
            v-model="monthdays"
            :style="{ maxWidth: '22rem' }"
            :options="monthdaysOptions"
            @input="doIt"
          />
          <mdc-select
            v-else
            v-model="daysExt"
            :options="daysExtOptions"
            dense
            @input="doIt"
          />
        </div>
        <div
          v-if="['day', 'week', 'month', 'year'].includes(every.id)"
          class="flex flex-wrap"
        >
          <later-calendar
            v-model="startDate"
            class="mr-6"
            label="Start date"
            @input="doIt"
          />
          <mdc-select
            v-model="end"
            :options="endOptions"
            class="mr-6"
            label="End"
            dense
            @input="doIt"
          />
          <later-stepper
            v-if="end.id === 'after'"
            v-model="endX"
            :max="1000"
            @input="doIt"
          >
            <span v-text="plural('time', endX)" />
          </later-stepper>
          <later-calendar
            v-else
            v-model="endDate"
            label="End date"
            @input="doIt"
          />
        </div>
      </div>
      <div
        class="rounded bg-gray-lighter p-2 mt-2 ml-auto self-start"
        style="width: 260px"
      >
        <div v-if="error" class="text-error" v-text="error" />
        <div v-else class="later-form-label" v-text="recurComputed" />
        <div
          ref="dates"
          :style="every.id === 'times' ? {} : { maxHeight: '7rem' }"
          class="font-mono text-xs overflow-y-scroll pl-1 m-2"
        >
          <div class="table">
            <div
              v-for="(date, index) in dates"
              :key="index"
              v-text="
                date.toLocaleString({
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import { DateTime, Info } from 'luxon';
import { recurString } from '@/utils/date';
import { alertDialog } from '@/utils/dialog';
import { plural } from '@/utils/string';
import axios from '@/utils/xhr';
import LaterButtonGroup from '@/components/button-group.vue';
import LaterCalendar from '@/components/calendar.vue';
import LaterStepper from '@/components/stepper.vue';
import MdcButton from '@/components/mdc/button.vue';
import MdcIcon from '@/components/mdc/icon.vue';
import MdcSelect from '@/components/mdc/select.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';
import MdcTextarea from '@/components/mdc/textarea.vue';

export default {
  components: {
    LaterButtonGroup,
    LaterCalendar,
    LaterStepper,
    MdcButton,
    MdcIcon,
    MdcSelect,
    MdcTextfield,
    MdcTextarea,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dates: [],
    datesTimes: [],
    textareaInput: '',
    textareaDirty: false,
    every: null,
    everyOptions: [],
    everyX: 1,
    end: null,
    endOptions: [],
    endX: 5,
    weekdays: [],
    weekdaysOptions: [],
    months: [],
    monthsOptions: [],
    onthe: null,
    ontheOptions: [],
    monthdays: [],
    monthdaysOptions: [],
    daysExt: null,
    daysExtOpts: [],
    startDate: null,
    endDate: null,
    error: null,
  }),

  computed: {
    daysExtOptions() {
      return !this.onthe || !this.onthe.id || this.onthe.id !== 'last'
        ? this.daysExtOpts.slice(0, -2)
        : this.daysExtOpts;
    },
    recurComputed() {
      return recurString(this.value);
    },
  },

  created() {
    // important we don't use mount here?
    this.everyOptions = [
      { id: 'day', label: 'Daily' },
      { id: 'week', label: 'Weekly' },
      { id: 'month', label: 'Monthly' },
      { id: 'year', label: 'Yearly' },
      { id: 'dates', label: 'Pick Dates' },
      { id: 'times', label: 'Pick Times' },
      { id: 'manual', label: 'Manual Entry' },
    ];
    this.endOptions = [
      { id: 'after', label: 'After' },
      { id: 'on', label: 'On' },
    ];
    this.weekdaysOptions = this.arrToObj(Info.weekdays('short'));
    this.monthsOptions = this.arrToObj(Info.months('short'));
    this.ontheOptions = [{ id: 'days', label: 'Day(s)' }]
      .concat(this.arrToObj(['First', 'Second', 'Third', 'Fourth', 'Fifth']))
      .concat({ id: 'last', label: 'Last' });
    this.monthdaysOptions = this.arrToObj([
      '1ˢᵗ',
      '2ⁿᵈ',
      '3ʳᵈ',
      '4ᵗʰ',
      '5ᵗʰ',
      '6ᵗʰ',
      '7ᵗʰ',
      '8ᵗʰ',
      '9ᵗʰ',
      '10ᵗʰ',
      '11ᵗʰ',
      '12ᵗʰ',
      '13ᵗʰ',
      '14ᵗʰ',
      '15ᵗʰ',
      '16ᵗʰ',
      '17ᵗʰ',
      '18ᵗʰ',
      '19ᵗʰ',
      '20ᵗʰ',
      '21ˢᵗ',
      '22ⁿᵈ',
      '23ʳᵈ',
      '24ᵗʰ',
      '25ᵗʰ',
      '26ᵗʰ',
      '27ᵗʰ',
      '28ᵗʰ',
      '29ᵗʰ',
      '30ᵗʰ',
      '31ˢᵗ',
    ]);
    this.daysExtOpts = this.arrToObj(Info.weekdays('long')).concat(
      { id: 'weekday', label: 'Weekday' },
      { id: 'weekendday', label: 'Weekend Day' }
    );
    if (this.value) {
      this.everyX = this.value.everyX;
      [this.every] = this.everyOptions.filter(
        (every) => every.id === this.value.every
      );
      [this.end] = this.endOptions.filter((end) => end.id === this.value.end);
      [this.onthe] = this.ontheOptions.filter(
        (onthe) => onthe.id === this.value.onthe
      );
      [this.daysExt] = this.daysExtOptions.filter(
        (day) => day.id === this.value.daysExt
      );
      if (this.value.weekdays)
        this.weekdays = this.weekdaysOptions.filter((day) =>
          this.value.weekdays.map((weekday) => weekday.id).includes(day.id)
        );
      if (this.value.monthdays)
        this.monthdays = this.monthdaysOptions.filter((day) =>
          this.value.monthdays.map((monthday) => monthday.id).includes(day.id)
        );
      if (this.value.months)
        this.months = this.monthsOptions.filter((month) =>
          this.value.months.map((mnth) => mnth.id).includes(month.id)
        );
      [this.startDate] = this.value.dates;
      this.endDate = this.value.dates[this.value.dates.length - 1];
      this.endX = this.value.dates.length;
      this.dates = this.value.dates;
    }
    if (!this.everyX) this.everyX = 1;
    if (!this.every) [this.every] = this.everyOptions;
    if (!this.end || !this.endDate || !this.endDate.isValid)
      [this.end] = this.endOptions;
    if (!this.onthe) [this.onthe] = this.ontheOptions;
    if (!this.startDate || !this.startDate.isValid)
      this.startDate = DateTime.local().plus({ days: 1 });
    if (!this.endDate || !this.endDate.isValid)
      this.endDate = this.startDate.plus({ days: 5 });
    if (!this.daysExt)
      this.daysExt = this.daysExtOptions[this.startDate.weekday - 1];
    if (this.weekdays.length === 0)
      this.weekdays.push(this.weekdaysOptions[this.startDate.weekday - 1]);
    if (this.monthdays.length === 0)
      this.monthdays.push(this.monthdaysOptions[this.startDate.day - 1]);
    if (this.months.length === 0)
      this.months.push(this.monthsOptions[this.startDate.month - 1]);
    if (this.every.id === 'manual') this.syncTextArea();
    this.datesTimes = this.dates.map((d, i) => ({ d, i }));
    this.doIt = debounce(this.doIt, 200);
    if (this.dates.length === 0) this.doIt();
  },

  methods: {
    plural,
    changeInterval() {
      if (this.every.id === 'times')
        this.datesTimes = this.dates.map((d, i) => ({ d, i }));
      else if (this.every.id === 'manual') this.syncTextArea();
      this.doIt();
    },
    addDateTime() {
      this.datesTimes.push({ d: DateTime.invalid('blank') });
      this.doIt();
    },
    removeDateTime(index) {
      this.datesTimes.splice(
        this.datesTimes.findIndex((d) => d.i === index),
        1
      );
      this.doIt();
    },
    doIt() {
      this.error = null;

      if (['day', 'week', 'month', 'year'].includes(this.every.id)) {
        this.dates = [];
        let next = this.startDate;
        const last = this.endDate.plus({ minutes: 1 });

        // validations
        if (!next || !next.isValid)
          return this.errorReport('Start date is missing');
        if (this.end.id === 'on' && (!last || !last.isValid))
          return this.errorReport('End date is missing');
        if (this.end.id === 'on' && last <= next)
          return this.errorReport('End date must be after start date');

        // todo see if condition exists: ex. Third weekday
        if (this.onthe.id !== 'last' && typeof this.daysExt.id === 'string')
          this.daysExt = this.daysExtOptions[this.startDate.weekday - 1];
        if (this.every.id === 'day') {
          while (
            this.end.id === 'after'
              ? this.dates.length < this.endX
              : next <= last
          ) {
            this.dates.push(next);
            next = next.plus({ days: this.everyX });
          }
        } else if (this.every.id === 'week') {
          if (this.weekdays.length < 1)
            return this.errorReport('Select at least one day of the week');

          const chosendays = this.weekdays.map((weekday) => weekday.id);
          while (
            this.end.id === 'after'
              ? this.dates.length < this.endX
              : next <= last
          ) {
            if (chosendays.includes(next.weekday)) this.dates.push(next);
            next = next.plus(
              next.weekday === this.weekdaysOptions.length && this.everyX > 1
                ? { weeks: this.everyX, days: 1 }
                : { days: 1 }
            );
          }
        } else if (['month', 'year'].includes(this.every.id)) {
          if (this.every.id === 'year' && this.months.length < 1)
            return this.errorReport('Select at least one month of the year');
          if (this.onthe.id === 'days' && this.monthdays.length < 1)
            return this.errorReport('Select at least one day of the month');

          let poop = 10000;
          while (
            this.end.id === 'after'
              ? this.dates.length < this.endX
              : next <= last
          ) {
            poop -= 1;
            if (poop === 0) {
              throw new Error('infinite loop prevented');
            }
            if (
              this.every.id === 'year' &&
              !this.months.map((month) => month.id).includes(next.month)
            ) {
              next = next.plus({ months: 1 }).set({ day: 1 });
            } else if (this.onthe.id === 'days') {
              if (this.monthdays.map((day) => day.id).includes(next.day))
                this.dates.push(next);
              next = next.plus({ days: 1 });
            } else if (typeof this.onthe.id === 'number') {
              if (
                this.onthe.id === Math.floor((next.day - 1) / 7) + 1 &&
                this.daysExt.id === next.weekday
              )
                this.dates.push(next);
              next = next.plus({ days: 1 });
            } else if (this.onthe.id === 'last') {
              if (next.day < next.plus({ weeks: 1 }).day)
                next = next.set({ day: next.endOf('month').day });
              for (let i = 0; i < 7; i += 1) {
                if (
                  this.daysExt.id === next.weekday ||
                  (this.daysExt.id === 'weekday' && next.weekday <= 5) ||
                  (this.daysExt.id === 'weekendday' && next.weekday >= 6)
                ) {
                  if (this.end.id !== 'on' || next <= last)
                    this.dates.push(next);
                  next = next.plus({ week: 1 }).set({ day: 1 });
                  break;
                }
                next = next.minus({ days: 1 });
              }
            }
            if (this.dates.length > 0 && this.everyX > 1) {
              if (this.every.id === 'month' && next.day === 1)
                next = next.plus({ months: this.everyX - 1 });
              if (
                this.every.id === 'year' &&
                next.day === 1 &&
                next.month === 1
              )
                next = next.plus({ years: this.everyX - 1 });
            }
          }
        }

        if (this.dates.length < 1)
          return this.errorReport('There are no valid dates in this period');

        if (this.end.id === 'after')
          this.endDate = this.dates[this.dates.length - 1];
        else this.endX = this.dates.length;

        const emit = {
          every: this.every.id,
          everyX: this.everyX,
          end: this.end.id,
          dates: this.dates,
        };
        if (this.every.id === 'week')
          Object.assign(emit, { weekdays: this.weekdays });
        if (this.every.id === 'year')
          Object.assign(emit, { months: this.months });
        if (['month', 'year'].includes(this.every.id)) {
          Object.assign(emit, { onthe: this.onthe });
          if (this.onthe.id === 'days')
            Object.assign(emit, { monthdays: this.monthdays });
          else Object.assign(emit, { daysExt: this.daysExt });
        }
        this.$emit('input', emit);
      } else if (['dates', 'times', 'manual'].includes(this.every.id)) {
        if (this.every.id === 'times')
          this.dates = this.datesTimes
            .map((d) => d.d)
            .filter((d) => d.isValid)
            .sort((a, b) => a.ts - b.ts);

        if (this.dates.length < 1)
          return this.errorReport('There are no dates entered.');

        this.$emit('input', {
          every: this.every.id,
          dates: this.dates,
        });
      }
      this.$nextTick(() => {
        if (this.$refs && this.$refs.dates)
          this.$refs.dates.scrollTop = this.$refs.dates.scrollHeight;
      });

      return true;
    },

    errorReport(error) {
      this.error = error;
      this.$emit('input', {
        invalid: true,
        every: this.every.id,
        everyX: this.everyX,
        end: this.end.id,
        weekdays: this.weekdays,
        months: this.months,
        onthe: this.onthe,
        daysExt: this.daysExt,
        monthdays: this.monthdays,
        dates: [],
      });
      return false;
    },

    arrToObj(arr) {
      const newArr = [];
      let index = 1;
      arr.forEach((item) => {
        const obj = {};
        obj.id = index;
        index += 1;
        obj.label = item;
        newArr.push(obj);
      });
      return newArr;
    },

    syncTextArea() {
      this.textareaInput = `${this.dates
        .map((d) => d.toLocaleString(DateTime.DATETIME_MED))
        .join('\n')}\n`;
      this.textareaDirty = false;
    },

    inputTextArea() {
      this.$emit('input', {
        invalid: true,
        every: 'manual',
        dates: this.dates,
      });
      this.textareaDirty = true;
    },

    processTextarea(event) {
      if (!event.sourceCapabilities) return;
      if (!this.textareaDirty) {
        this.$store.commit('snackbarMessage', 'All dates are valid.');
        return;
      }

      this.dates = [];
      axios
        .post('/test-dates', {
          dates: this.textareaInput.split('\n').filter((d) => d),
        })
        .then((response) => {
          response.data.validDates.forEach((d) =>
            this.dates.push(DateTime.fromSQL(d))
          );
          if (
            response &&
            response.data &&
            response.data.invalidDates &&
            response.data.invalidDates.length > 0
          ) {
            alertDialog(
              `Invalid or past date${
                response.data.invalidDates.length > 1 ? 's' : ''
              }`,
              response.data.invalidDates.join('<br />')
            );
          } else {
            this.syncTextArea();
          }
          this.doIt();
        });
    },
  },
};
</script>
