<template>
  <div @click="focusHandler" @keyup="focusHandler">
    <div class="max-w-screen-2xl sm:m-4">
      <div class="flex items-center mb-1 mr-4 md:mr-0">
        <mdc-icon
          :title="isDirty ? 'Save and return' : 'Return'"
          @click.prevent.native="$router.back()"
        >
          arrow_back
        </mdc-icon>
        <div class="flex-1" />
        <strong v-show="isDirty" class="mx-1">*</strong>
        <later-status :status="status" />
      </div>
      <form
        v-if="account"
        class="p-4 sm:rounded sm:shadow bg-white"
        @submit.prevent
      >
        <div id="contacts" class="relative ml-4">
          <i
            v-show="cc.length === 0 && bcc.length === 0 && !isReadOnly"
            class="
              material-icons
              absolute
              left-0
              top-0
              cursor-pointer
              text-lg text-gray-dark
              hover:text-black hover:bg-gray-light
              rounded
              z-10
              -ml-6
              mt-4
            "
            @click="showCcs = !showCcs"
            v-text="showCcs ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          />
          <later-contacts
            ref="to"
            v-model="to"
            :disabled="isReadOnly"
            label="To"
            autofocus
            @input="dirtied('contacts')"
          />
          <later-contacts
            v-if="showCcs || cc.length > 0"
            v-model="cc"
            :disabled="isReadOnly"
            label="Cc"
            @input="dirtied('contacts')"
          />
          <later-contacts
            v-if="showCcs || bcc.length > 0"
            v-model="bcc"
            :disabled="isReadOnly"
            label="Bcc"
            @input="dirtied('contacts')"
          />
        </div>
        <mdc-select
          v-model="account"
          :options="$store.getters.verifiedAccounts"
          :disabled="isReadOnly"
          value-key="hash"
          label-key="email"
          label="From"
          dense
          @change.native="dirtied('from')"
        >
          <template slot="option" slot-scope="props">
            {{ props.option.name }} &lt;{{ props.option.email }}&gt;
          </template>
        </mdc-select>
        <div id="when" class="flex flex-wrap">
          <mdc-select
            v-if="!isReadOnly"
            v-model="recur"
            :options="recurOptions"
            :keys="{ value: 'id', label: 'label' }"
            class="mr-6"
            label="Send"
            dense
            @input="dirtied('when')"
          />
          <later-calendar
            v-if="recur.id === 'once'"
            v-model="sendAt"
            :disabled="isReadOnly"
            label="When to send"
            @input="dirtied('when')"
          />
          <later-calendar-recurring
            v-else
            v-model="recurObj"
            :disabled="isReadOnly"
            :has-focus="focusedComponent === 'when'"
            class="flex-1"
            label="When to send"
            @input="dirtied('when')"
          />
        </div>
        <mdc-textfield
          v-model="subject"
          :disabled="isReadOnly"
          label="Subject"
          class="w-full"
          @input="dirtied('subject')"
        />
        <later-attachments v-model="attachments" :disabled="isReadOnly" />
        <later-wysiwyg
          id="body"
          v-model="body"
          :disabled="isReadOnly"
          :has-focus="focusedComponent === 'body'"
          @input="dirtied('body')"
        />
        <div class="flex items-center">
          <mdc-button
            v-if="!isReadOnly"
            :disabled="status === 'scheduled' && !isDirty"
            class="mr-8"
            ripple
            raised
            @click.prevent.native="submitYo(true)"
          >
            Commit
          </mdc-button>
          <mdc-menu>
            <mdc-icon slot="trigger" title="More options"> more_vert </mdc-icon>
            <template slot="list">
              <div
                class="mdc-list-item"
                role="menuitem"
                @click="submitYo(false)"
                @keyup.enter="submitYo(false)"
              >
                <i
                  class="material-icons mdc-list-item__graphic"
                  aria-hidden="true"
                  >insert_drive_file</i
                >
                <span class="mdc-list-item__text">Save as draft</span>
              </div>
              <!-- <div role="separator" class="mdc-list-divider" /> -->
              <div
                class="mdc-list-item"
                role="menuitem"
                title="With this option, you will not be able to view or edit this message until after it has been sent!"
                @click="confirmHide"
                @keyup.enter="confirmHide"
              >
                <i
                  class="material-icons mdc-list-item__graphic"
                  aria-hidden="true"
                  >lock</i
                >
                <span class="mdc-list-item__text">Put in the vault...</span>
              </div>
              <div
                class="mdc-list-item"
                role="menuitem"
                title="Delete this message"
                @click="trash"
                @keyup.enter="trash"
              >
                <i
                  class="material-icons mdc-list-item__graphic"
                  aria-hidden="true"
                  >delete</i
                >
                <span class="mdc-list-item__text">Trash</span>
              </div>
              <!-- <div role="separator" class="mdc-list-divider" /> -->
              <router-link
                v-if="hasLogs"
                :to="`/email/logs/${$route.params.hash}`"
                class="mdc-list-item"
              >
                <i
                  class="material-icons mdc-list-item__graphic"
                  aria-hidden="true"
                  >list_alt</i
                >
                <span class="mdc-list-item__text">View delivery logs</span>
              </router-link>
            </template>
          </mdc-menu>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { dateFromServer, dateToServer } from '@/utils/date';
import { alertDialog, confirmDialog } from '@/utils/dialog';
import LaterAttachments from '@/components/attachments.vue';
import LaterContacts from '@/components/contacts.vue';
import LaterCalendar from '@/components/calendar.vue';
import LaterCalendarRecurring from '@/components/calendar-recurring.vue';
import LaterStatus from '@/components/status.vue';
import LaterWysiwyg from '@/components/wysiwyg.vue';
import MdcButton from '@/components/mdc/button.vue';
import MdcIcon from '@/components/mdc/icon.vue';
import MdcMenu from '@/components/mdc/menu.vue';
import MdcSelect from '@/components/mdc/select.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';

export default {
  components: {
    LaterAttachments,
    LaterContacts,
    LaterCalendar,
    LaterCalendarRecurring,
    LaterStatus,
    LaterWysiwyg,
    MdcButton,
    MdcIcon,
    MdcMenu,
    MdcSelect,
    MdcTextfield,
  },
  data: () => ({
    account: null,
    to: [],
    cc: [],
    bcc: [],
    showCcs: false,
    sendAt: null,
    recur: {},
    recurOptions: [
      { id: 'once', label: 'Once' },
      { id: 'recur', label: 'Recurring' },
    ],
    recurObj: null,
    subject: '',
    attachments: [],
    body: '',
    status: 'draft',
    hidden: false,
    dirty: {},
    focusedComponent: 'contacts',
    hasLogs: false,
  }),

  computed: {
    isDirty() {
      return !this.isReadOnly && Object.keys(this.dirty).length > 0;
    },
    isReadOnly() {
      return (
        !this.status || this.status === 'trash' || this.status === 'queued'
      );
    },
  },

  mounted() {
    axios.get(`/email/${this.$route.params.hash}/edit`).then((response) => {
      this.account = response.data.account.hash;
      this.$store.state.user.accounts.forEach((from) => {
        if (from.hash === this.account) this.account = from;
      });
      // combine contacts with groups and sort them by order
      const types = ['to', 'cc', 'bcc'];
      const recipients = {};
      types.forEach((type) => {
        recipients[type] = [];
      });
      response.data.contacts.forEach((contact) =>
        recipients[contact.type].push(contact)
      );
      response.data.groups.forEach((contact) =>
        recipients[contact.type].push(contact)
      );
      types.forEach((type) => {
        recipients[type].sort((a, b) => a.order - b.order);
        this[type] = recipients[type];
      });
      if (this.cc.length > 0 || this.bcc.length > 0) this.showCcs = true;
      this.sendAt = dateFromServer(response.data.send_at);
      this.recurObj = response.data.recur_obj;
      if (this.recurObj && this.recurObj.dates)
        this.recurObj.dates = this.recurObj.dates.map((date) =>
          dateFromServer(date)
        );
      this.recur = this.recurOptions[this.recurObj ? 1 : 0];
      this.subject = response.data.subject;
      this.attachments = response.data.attachments;
      this.body = response.data.body;
      this.status = response.data.deleted_at ? 'trash' : response.data.status;
      this.hidden = response.data.hidden;
      this.hasLogs = response.data.logs_count > 0;
      this.dirty = {};
    });

    window.addEventListener('beforeunload', this.handleUnload);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUnload);
  },

  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.submitYo(this.status === 'scheduled')
        .then(() => {
          next();
        })
        .catch((e) => {
          if (e && e.response && [401, 419].includes(e.response.status)) next();
          // session expired, nothing we can do now
          else next(false);
        });
    } else next();
  },

  methods: {
    handleUnload(e) {
      if (!this.isDirty) return undefined;
      const msg = 'You have unsaved changes!';
      (e || window.event).returnValue = msg; // Gecko + IE
      return msg; // Gecko + Webkit, Safari, Chrome etc.
    },

    submitYo(validate, hidden = false) {
      //  todo: wait until !loading and !committing contacts

      // find what's dirty, and prep it
      const params = {};
      const errors = [];
      if (this.dirty.from) params.from = this.account.hash;
      if (this.dirty.contacts) {
        ['to', 'cc', 'bcc'].forEach((type) => {
          params[type] = [];
          this[type].forEach((contact) => {
            const obj = {};
            obj[contact.email ? 'c' : 'g'] = contact.hash;
            if (contact.hash) params[type].push(obj);
            else
              errors.push('Double-check the email addresses you have entered.');
          });
        });
      }
      if (this.dirty.when) {
        if (this.recur.id === 'once') {
          params.send_at = dateToServer(this.sendAt);
          params.recur_obj = null;
        } else {
          const recurParam = { ...this.recurObj };
          recurParam.dates = recurParam.dates.map((date) => dateToServer(date));
          [params.send_at] = recurParam.dates;
          params.recur_obj = JSON.stringify(recurParam);
        }
      }
      if (this.dirty.subject) params.subject = this.subject;
      if (this.dirty.body) params.body = this.body;

      // if committing, need to validate input
      if (validate) {
        if (this.to.length + this.cc.length + this.bcc.length === 0)
          errors.push('Please specify at least one recipient.');
        if (this.recur.id === 'once' && !this.sendAt.isValid)
          errors.push('Please specify a send date.');
        if (this.recur.id === 'recur' && this.recurObj.dates.length < 1)
          errors.push('Please specify at least one send date.');
        params.validate = true;
        if (hidden) params.hidden = true;
      }
      // i dont know how to wait for date checks, gotta stop if not yet saved
      if (errors.length === 0) {
        if (
          this.recur.id === 'once' &&
          !this.sendAt.isValid &&
          this.sendAt.invalid &&
          this.sendAt.invalid.reason === 'BAD_DATE'
        )
          errors.push('Please make sure your date has been validated.');
        else if (this.recur.id === 'recur' && this.recurObj.invalid)
          errors.push('Please make sure your dates have been validated.');
      }

      return new Promise((resolve, reject) => {
        if (errors.length > 0) {
          alertDialog('Whoops!', errors.join('<br>'));
          reject(errors);
          return;
        }
        axios
          .post(`/email/${this.$route.params.hash}`, params)
          .then(() => {
            this.dirty = {};
            if (validate) {
              this.$store.commit(
                'snackbarMessage',
                'Your email has been scheduled to be sent!'
              );
              this.$router.push('/email');
            } else {
              this.$store.commit('snackbarMessage', 'Email has been saved.');
              if (this.status !== 'draft') {
                this.status = 'draft';
                this.$router.push('/email');
              }
            }
            resolve();
          })
          .catch((e) => reject(e));
      });
    },

    dirtied(field) {
      if (
        this.dirty &&
        !Object.prototype.hasOwnProperty.call(this.dirty, field)
      ) {
        this.$set(this.dirty, field, true);
      }
    },

    confirmHide() {
      let firstDate = this.sendAt;
      if (!firstDate && this.recurObj && Array.isArray(this.recurObj.dates))
        [firstDate] = this.recurObj.dates;
      confirmDialog(
        'Put it in the vault!',
        `This means that you will not be able to view or edit this message until the time of delivery${
          firstDate && firstDate.isValid
            ? ` (${firstDate.toRelativeCalendar()})`
            : ''
        }. The subject will still be visible, so make sure it is helpful. You may delete it if you decide not to send it.`,
        'Do it!'
      )
        .then(() => {
          this.submitYo(true, true);
        })
        .catch(() => {});
    },

    trash() {
      axios
        .post('/email/trash', {
          emails: [this.$route.params.hash],
          action: 'trash',
        })
        .then(() => {
          this.dirty = {};
          this.$store.commit(
            'snackbarMessage',
            'Email has been moved to trash.'
          );
          this.$router.push('/email');
        });
    },

    focusHandler(event) {
      if (
        this.isReadOnly ||
        !event ||
        (event.type === 'keyup' && event.keyCode !== 9)
      )
        return;
      let count = 0;
      let el = event.target;
      while (el && el.tagName.toLowerCase() !== 'body' && count < 100) {
        if (el.id === 'contacts') {
          if (this.focusedComponent !== 'contacts') {
            this.focusedComponent = 'contacts';
          }
          return;
        }
        if (el.id === 'when') {
          this.focusedComponent = 'when';
          return;
        }
        if (el.id === 'body') {
          this.focusedComponent = 'body';
          return;
        }
        el = el.parentElement;
        count += 1;
      }
      this.focusedComponent = '';
    },
  },
};
</script>
